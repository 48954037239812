<template>
    <div class="backdrop">
      <div class="b-modal">
          <div class="modal-header text-right">
              <h5 class="mt-2 text-white">Add Individual Associates</h5>
              <p @click="closeModal" class="x">x</p>
          </div>
        <div class="modalBody">
         
             <div v-if="step == '2'" class="mt-5">
                  <p class="text-white">Successfully added.</p>
                  <p>
                  <button class="btn btn-main submit" @click="done">Done</button>
                  </p>
              </div>

              <div v-if="step == '1'">
                <form  v-on:submit.prevent="submitProfile" >
                  <h2 class="text-white">Individual Associates Basic Info</h2>
                  <hr>
                 
                   
                    <div class="form-group" >
                      <label for="formGroupExampleInput">Full Name:</label>
                      <input type="text" class="form-control" id="formGroupExampleInput" v-model="profile.fullName">
                    </div>
                   
                  
                    <div class="form-group" >
                      <label for="formGroupExampleInput">Contact details:</label>
                      <input type="text" class="form-control" id="formGroupExampleInput" v-model="profile.contactDetails" >
                    </div>
                    <div class="form-group">
                      <label for="address">Designation:<strong>*</strong></label>
                      <select id="bType" v-model="profile.designation" class="form-select">
                        <option value="" disabled>Select designation</option>
                        <option value="director">Director</option>
                        <option value="shareholder">Shareholder</option>
                        <option value="ubo">Ultimate Beneficial Owner</option>
                        <option value="authorizedSignatory">Authorized Signatory/Controller</option>
                      </select>
                    </div>
                    <div >
                  </div>
                   
                   
                    <div class="form-group ">
                      <button type="submit" class="btn btn-main" id="loginBtn">Submit Individual Associates Basic Info</button>
                    </div>
    
                    
                 
                </form>
                
    
              </div>
              
  
      
           
         
        </div>
      </div>
    </div>
  </template>
  
  <script>
  
  export default {
      components: {
  
      },
      data: function() {
          return {
            uploadError:'',
              step:'1',
              profile:{
                  fullName:"",
                  contactDetails:"",
                  designation:"",
              },
             
              errors: {
                  profile: {
                      show:false,
                      msg: ""
                  },
              },
              
          }
      },
  
      computed: {
       
      },
  
      mounted() {
       
      },
  
      watch: {
        
      },
  
      methods: {
        submitProfile(){
        if(this.profile.fullName == "" || this.profile.fullName == null || this.profile.fullName == undefined)  {
            this.errors.profile.show = true;
            this.errors.profile.msg = "Fullname is required";
            setTimeout(()=>{
              this.errors.profile.show = false;
              this.errors.profile.msg = "";
            }, 3000)
            return false;
            }

            if(this.profile.contactDetails == "" || this.profile.contactDetails == null || this.profile.contactDetails == undefined)  {
            this.errors.profile.show = true;
            this.errors.profile.msg = "Contact details is required";
            setTimeout(()=>{
              this.errors.profile.show = false;
              this.errors.profile.msg = "";
            }, 3000)
            return false;
            } 
            if(this.profile.designation == "" || this.profile.designation == null || this.profile.designation == undefined)  {
            this.errors.profile.show = true;
            this.errors.profile.msg = "Linked In is required";
            setTimeout(()=>{
              this.errors.profile.show = false;
              this.errors.profile.msg = "";
            }, 3000)
            return false;
            } 


            this.errors.profile.show = false;
            const params = {
              name: this.profile.fullName,
              contactDetails: this.profile.contactDetails,
              designation: this.profile.designation,
              link: 'link'
            }

            const data = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');


            const options = {
            method: 'POST',
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                'blox-authz': localStorage.getItem("tokenId") 
            }, 
            data,
            url: '/cx/profile/corp/director/add',
            };

            this.axios(options)
            .then((response) => {
            console.log(response.data)
              this.step = '2'
               
            }).catch((err) => {
              console.log(err.response.data)     

                if(err.response.data.fieldErrors){
                    this.errors.profile.show = true;
                    this.errors.profile.msg = err.response.data.fieldErrors[0].defaultMessage

                }
                 if(err.response.data.msgCode == '00001') {
                  window.location.href = "/#/";
                }  

                if(err.response.data.msgCode == '00020') {

                  setTimeout( () => {
                  window.location.href = "/#/setup-profile";
                },1000)
                  
              }  
            })
        
      },
       
          

          closeModal(){
              this.$emit("close")
              window.location.reload();
          },
  
          done(){
            this.$emit("close");
            window.location.reload();
          },
        
        
      }
  }
  </script>
  
  <style scoped>
  .backdrop {
      top: 0;
      right:0;
      position: fixed;
      width: 100vw;
      height: 100vh;
      z-index: 98;
      background: rgba(0, 0, 0, 0.6);
  
  }
  .b-modal {
    width: 620px;
      height: auto;
      margin: 50px auto;
      background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
      border-radius: 20px;
      border-color:white;
      border-width:2px;
      z-index: 99;
      overflow-x: hidden !important;
      color:black;
      max-height: calc(100vh - 110px);
      overflow-y: auto;
  }
  
  .modal-header {
    background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
      margin-right:0px;
      font-size:25px;
      display:flex;
      justify-content:space-between;
  }
  
  .modal-title{
      margin: 0 auto;
      color:white;
  }
  
  .modalBody {
    background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
      width:100%;
      padding:50px;
      border-radius: 10px;
      display: flex;
      justify-content: center;
  }
  
  .buttonWrapper {
      width:100%;
      margin-top: 10px;
      padding-top: 15px;
  }
  
  .x {
      cursor:pointer;
  }
  .red{
      color: red;
  }

 
  
  
      @media screen and (max-width: 1100px) {
      
            
      }
     @media screen and (max-width: 800px) {
       
    }
  
      @media screen and (max-width: 600px) {
         
          
      }
  
      @media screen and (min-width: 280px) and (max-width: 420px) {
  
         .b-modal {
              width: 350px;
              height: auto;
              margin-top: 50px;
              background: rgb(13, 2, 57);
              border-radius: 20px;
              border-color:white;
              border-width:2px;
              z-index: 1;
          }
  
          .modalBody {
            background: rgb(13, 2, 57);
              width:100%;
              padding: 20px 10px;
              border-radius: 10px;
          }
          
  
    
        
  
  
      }
  
      @media screen and (min-width: 480px) and (max-width: 580px) {
          .b-modal {
                  width: 405px;
          }
  
      
       
      }
      .wrapper{
        display: flex;
         padding: 2em;
        justify-content: center !important;
        margin: 0 auto !important;
    }
   
      .product-grid {
        display: flex;
       
      }

    .product-card {
        background-image: linear-gradient(
                180deg,
              #10184d 29%,
              #00103d 59%,
                #010c2a 88%
        );
        box-shadow:0 0 30px rgba(0,0,0,0.3);
        border: 1px solid transparent;
        border-radius: 12px;
        width: 7rem;
        height: auto;
        text-align: center;
        margin: 10px !important;
        color: white;
        cursor: none;
    
    }


    .product-image {
      padding: 10px 0px;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        margin: 0 auto;
        background-color: none !important;
      width: 100%;
      max-width: 80px;
      height: auto;
      margin-bottom: 10px;
    }
  </style>