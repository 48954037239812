<template>
  <div class="main-content">
    <img :src="require('../assets/images/bg1.jpg')" class="bg" />
      <div class="header-wrapper">
        <viewHeader />
        
      </div> 
    
    <div class="content-wrapper">
    <h3 class="text-white"> SETUP CORPORATE ACCOUNT</h3>
     
      <div class="modal-body">
        <div class="alert alert-danger" v-if="errors.profile.show">
          {{ errors.profile.msg }}
      </div>
        <div>
          <div class="progress-bars">
            <div class="progress-bar" :class="{ active: currentStep >= 1 }"></div>
            <div class="progress-bar" :class="{ active: currentStep >= 2 }"></div>
            <div class="progress-bar" :class="{ active: currentStep >= 3 }"></div>
            <div class="progress-bar" :class="{ active: currentStep >= 4 }"></div>
            <div class="progress-bar" :class="{ active: currentStep >= 5 }"></div>
            <div class="progress-bar" :class="{ active: currentStep >= 6 }"></div>
            <div class="progress-bar" :class="{ active: currentStep >= 7 }"></div>
            <div class="progress-bar" :class="{ active: currentStep >= 8 }"></div>
          </div>
          <div v-show="currentStep === 8">
            <div class="table-responsive">
              <h6 class="text-white">Individual Associates:</h6>
              <br>
              <hr>
              <br>
                  <table class="table">
                  <thead>
                      <tr >
                          <th>NAME</th>
                          <th>CONTACT DETAILS</th>
                          <th>DESIGNATION</th>
                          <th></th>
                         
                      </tr>
                      </thead>
                      <tbody>
                         
                      <tr v-for="member in directors" :key="member.id">
                          <td>{{member.name}}</td>
                          <td>{{member.contactDetails}}</td>
                          <td v-if="member.designation == 'ubo'">Ultimate Beneficiary Owner</td>
                          <td v-if="member.designation == 'shareholder'">Shareholder</td>
                          <td v-if="member.designation == 'director'">Director</td>
                          <td v-if="member.designation == 'authorizedSignatory'">Authorized Signatory or Controller</td>
                          <td ><button class="btn btn-display" @click="openUpload(member)">Upload</button></td>
                         
                      </tr>
                      </tbody>
                  </table>

                  <!-- <table class="table" v-if="init.corpDetails.diretors.length = 0">
                    <thead>
                        <tr >
                          No Details found
                        </tr>
                        </thead>
                       
                    </table> -->
          </div>
            

          </div>
  
         
        <div>
          <div class="form-group" style="color: white; padding: 20px; font-size: 1rem;">
            <button class="btn btn-display"  @click="addMember()" >Add Individual Associates</button>
          </div>
          
        </diV>
        <div>
          <div class="form-group" style="color: white; padding: 20px; font-size: 1rem;">
            <button class="btn btn-main" @click="goToHome">HOME</button>
          </div>
        </div>

        </div>
      </div>
      <UploadMember v-if="showUpload" @close="openUpload" :memberName = "memberName"></UploadMember>
      <AddMember v-if="showAddMember" @close="addMember"></AddMember>
      <ChangePin v-if="showChangePin" @close="openChangePin"></ChangePin>
      
    </div>

  </div>
</template>

<script>
  import UploadMember from '@/components/modals/UploadMember.vue'
  import AddMember from '@/components/modals/AddMember.vue'
  import viewHeader from '@/views/viewHeader.vue'
  import ChangePin from '@/components/modals/ChangePin.vue'

  export default {
    name: 'BoardOfDirectorsComponent',
    components: {
      AddMember,
      UploadMember,
      viewHeader,
      ChangePin
    },
    computed: {
      init() {
        return this.$store.state.init;
      },
    },
    data: function() {
      return { 
        state:'selectAcct',
        accountType: 'individual',
          currentStep: 8,
         
        errors: {
          profile: {
            msg: "",
            show: false,
           
          }
        },
        showChangePin: false,
        showUpload: false,
        showAddMember: false,
        numberValue: '',
        directors:[],
        memberName: ''
    
       
      }
    },
    computed:{
       init() {
        	return this.$store.state.init;
    	},
    
      show() {
        return this.$store.state.show;
      },

    },
    methods: {
      openChangePin(){
            this.show.showChangePin = !this.show.showChangePin
      },
      openUpload(member){
        this.showUpload = !this.showUpload
        this.memberName = member

      },
      goToHome:function(){
        this.$router.push({name: 'Dashboard'})
      },
        addMember(){
          this.showAddMember = !this.showAddMember
        },
     
     
      isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
          evt.preventDefault();
        } else {
          return true;
        }
      },
      submitProfile(){
        if(this.profile.firstName == "" || this.profile.firstName == null || this.profile.firstName == undefined)  {
            this.errors.profile.show = true;
            this.errors.profile.msg = "First name is required";
            setTimeout(()=>{
              this.errors.profile.show = false;
              this.errors.profile.msg = "";
            }, 3000)
            return false;
            }

            if(this.profile.lastName == "" || this.profile.lastName == null || this.profile.lastName == undefined)  {
            this.errors.profile.show = true;
            this.errors.profile.msg = "Last name is required";
            setTimeout(()=>{
              this.errors.profile.show = false;
              this.errors.profile.msg = "";
            }, 3000)
            return false;
            } 


            this.errors.profile.show = false;
            const params = {
            firstName: this.profile.firstName,
            lastName: this.profile.lastName
            }

            const data = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');


            const options = {
            method: 'POST',
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                'blox-authz': localStorage.getItem("tokenId") 
            }, 
            data,
            url: '/cx/profile/corp/director/add',
            };

            this.axios(options)
            .then((response) => {
            console.log(response.data)
         
               
            }).catch((err) => {
              console.log(err.response.data)     

                if(err.response.data.fieldErrors){
                    this.errors.profile.show = true;
                    this.errors.profile.msg = err.response.data.fieldErrors[0].defaultMessage

                }
                 if(err.response.data.msgCode == '00001') {
                  window.location.href = "/#/";
                }  

                if(err.response.data.msgCode == '00020') {

                  setTimeout( () => {
                  window.location.href = "/#/setup-profile";
                },1000)
                  
              }  
            })
        
      },
     

      sessionCheck() {
        const options = {
                method: 'POST',
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    'blox-authz': localStorage.getItem("tokenId") 
                }, 
              
                url: '/cx/sessioncheck',
                }
          this.axios(options)
          .then((response) => {
           this.init.hasSession = true;
           this.getProfile();
          }).catch((err) => {
            this.init.hasSession = false;
                
                // if(err.response.data.msgCode == '00001') {
                //   window.location.href = "/#/";
                // }  
  
               
  
              })
      },


      getProfile() {
        const options = {
                method: 'GET',
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    'blox-authz': localStorage.getItem("tokenId") 
                }, 
              
                url: '/cx/profile',
                }
                this.axios(options)
                .then((response) => {
                this.init.profile = response.data;
                  this.init.corpDetails.corpInfo = response.data.corpDetails.corpInfo
                  this.init.corpDetails.corpOfficeAddress = response.data.corpDetails.corpOfficeAddress
                  this.init.corpDetails.tradingAddress = response.data.corpDetails.tradingAddress
                console.log(response.data.corpDetails.diretors)

               this.directors = response.data.corpDetails.diretors

               
              
                }).catch((err) => {
                    this.init.hasSession = false;
                })
            },
    },
    mounted() {
    this.sessionCheck();
    },
    watch: {
     
    }
  }
</script>
 
<style scoped>
  .btn-card-upload{
  background: linear-gradient(to bottom, #CFA76B 0%, #695536 100%);
  }
  .btn-card-upload:hover{
  background: linear-gradient(to bottom, #CFA76B 100%, #695536 0%);
  }
.basic{
  background-color: rgb(110, 157, 92);
  color: white;
}
.enhanced{
  background-color: rgb(74, 166, 38);
  color: white;
}
.verified{
  background-color: green;
  color: white;
}
  
    .header-wrapper{
      padding: 1em;
    }
    .content-wrapper{
      padding: 5em;
    }
  
    .progress-bars {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .progress-bar {
    width: 100px;
    height: 10px;
    background-color: lightgray;
    margin-right: 5px;
  }
  
  .active {
    background-color: green;
  }
  
  .three-column {
  display: flex;
  justify-content: space-between;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-column {
  flex-basis: calc(33.33% - 20px);
  margin-bottom: 20px;
  
}
.form-group{
  margin-bottom: 5px;
}

@media (max-width: 767px) {
  .form-column {
    flex-basis: 100%;
  }
}

  
  label {
    margin-right: 5px;
  }
  
  
     .btn-flag {
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
    }
     .mobile-container {
      /* position: relative; */
      .default-flag {
        width: 33px;
        margin-right: 0.25em;
      }
      
  
      .country-list {
        position: absolute;
        background: #ccc;
        color: #333;
        top: 70px;
        padding: 0.5em;
        height: 13em;
        overflow: auto;
        z-index: 3;
        .row {
          margin: 0 !important;
          .col-sm-6 {
            display: flex;
            padding: 0.5em;
            align-items: center;
            cursor: pointer;
            &:hover {
              background: darken(#ccc, 10%);
            }
            .country-name {
              margin-left: 0.5em;
            }
  
            span {
              font-size: 0.9em;
            }
          }
        }
      }
    }
  
    .flag-list-img {
      width: 33px;
    }
    .btn-back{
        float: left;
        background-color: #333;
        color: white;
    }
    .btn-save{
        float: right;
        background-color: rgb(124, 121, 121);
        color: white;
    }
    .birthdate{
        padding-top: 10px;
    }
   .table{
        padding: 10px;
        border-collapse: collapse;
        width: 100% !important;
        margin: 0 auto;
        background: #5227731d !important;
        color: white;
        text-align: center !important;
        font-size: 80%;
        border-radius: 10px !important;
        padding: 0.5em 2em;
        overflow-y: scroll;
        max-height: 700px; 
        z-index: 9999 !important;

   }
   .btn-group-card{
      display: block !important;
      justify-content: center;
      align-items: center;
      width: 100%;
 }
.btn-card{
  width: 100%;
  height: 50px;
  margin: 10px;
  text-align: start;
  border-radius: 10px !important;


}

.btn-card-check{
  background: #aa6c00;
  background: linear-gradient(to top, #aa6c00 0%, #f9cb10 100%);
}
.green{
  background: green;
}
.red{
  background: rgb(96, 89, 89);
}
</style>