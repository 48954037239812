<template>
  <div class="backdrop">
    <div class="b-modal">
        <div class="modal-header text-right" >
            <h5 class="text-white">Upload Document for {{memberName.name}}</h5>
            <p @click="closeModal" class="x">x</p>
        </div>
      <div class="modalBody">
        <div class="" v-if="step == '0'" ><button @click.prevent="uploadNow()">Continue</button>
          <div class="form-group" style="color: white; padding: 5px; font-size: 1rem;" >
              <p>Take a photo of your identification card and supporting documents. 
                <ol style="color: white; padding: 20px; font-size: 1rem;">
                  <li>
                     VALID ID
                  </li>
                  <li>
                    SELFIE
                 </li>
                  <li>
                     PROOF OF ADDRESS
                  </li>
                </ol>
                </p>
                
                <p> The photo should be: 
                <ol style="color: white; padding: 20px; font-size: 1rem;">
                  <li>
                     bright and clear
                  </li>
                  <li>
                     all corners of the document should be visible.
                  </li>
                </ol>
                <label>Valid placement</label>
                <div class="wrapper" >
                  
                  <div class="product-grid" >
                    <div  class="product-card">
                      <img :src="require('../../assets/id/pass-y.png')"
                      class="product-image"
                      img-top>
                    </div>
                   
                    <div  class="product-card">
                      <img :src="require('../../assets/id/doc-yes.png')"
                      class="product-image"
                      img-top>
                    </div>
                    <div  class="product-card">
                      <img :src="require('../../assets/id/id-yes.png')"
                      class="product-image"
                      img-top>
                    </div>
                    <div  class="product-card">
                      <img :src="require('../../assets/id/selfie-yes.png')"
                      class="product-image"
                      img-top>
                    </div>
                    

                  </div>  
                </div>

                <label>Invalid placement</label>
                <div class="wrapper" >
                  
                  <div class="product-grid" >
                    <div  class="product-card">
                      <img :src="require('../../assets/id/pass-n.png')"
                      class="product-image"
                      img-top>
                      <img :src="require('../../assets/id/pass-n2.png')"
                      class="product-image"
                      img-top>
                    </div>
                   
                    <div  class="product-card">
                      <img :src="require('../../assets/id/doc-n1.png')"
                      class="product-image"
                      img-top>
                      <img :src="require('../../assets/id/doc-n2.png')"
                      class="product-image"
                      img-top>
                    </div>
                    <div  class="product-card">
                      <img :src="require('../../assets/id/id-no.png')"
                      class="product-image"
                      img-top>
                      <img :src="require('../../assets/id/id-n1.png')"
                      class="product-image"
                      img-top>
                    </div>
                    <div  class="product-card">
                      <img :src="require('../../assets/id/selfie-n.png')"
                      class="product-image"
                      img-top>
                      <img :src="require('../../assets/id/selfie-n1.png')"
                      class="product-image"
                      img-top>
                    </div>
                    

                  </div>  
                </div>
              
              </p>
              

            </div>
        
   

      </div>
           <div v-if="step == '5'" class="mt-5">
                <p class="text-white">Upload successful. You will be notified once it has been approved.</p>
                <p>
                <button class="btn btn-main submit" @click="done">Done</button>
                </p>
            </div>
            

         <form v-on:submit.prevent="uploadImage($event)" v-if="step == '1'">
             <label for="idType">Document Type:</label>
                   
                <div class="payment-container text-left d-block">
                <form class="">
                     <div class="form-check px-5">
                    <input
                      class="form-check-input"
                      v-model="idType"
                      type="radio"
                      value="passport"
                      
                    />
                    <label class="form-check-label" for="passport">
                      Passport
                    </label>
                  </div>

                   <div class="form-check px-5">
                    <input
                      class="form-check-input"
                      v-model="idType"
                      type="radio"
                      value="nationalId"
                      
                    />
                    <label class="form-check-label" for="nationalId">
                      National ID
                    </label>
                  </div>

                   <div class="form-check px-5">
                    <input
                      class="form-check-input"
                      v-model="idType"
                      type="radio"
                      value="driversLicense"
                      
                    />
                    <label class="form-check-label" for="driversLicense">
                     Driver's License
                    </label>
                  </div>
                

                  
                </form>
                </div>
                    

          
            <div class="buttonWrapper" >
              
                <label v-if="idType == 'nationalId' || idType == 'driversLicense'"> {{idType}} (Front)</label>
                <label v-else> {{idType}} </label>
                <div class="dropbox text-white">
                  <input type="file" ref="fileInput" @change="handleFileSelect">
                  <p class="text-white">Selected file: {{ selectedFile }}</p>
                </div>
            </div>
            <div>
                <button type="submit" class="btn btn-main" id="saveBtn" :disabled="isSubmitting">Submit</button>
            </div>
            

            
        </form>

           <form v-on:submit.prevent="uploadImageBack($event)" v-if="step == '2'">

            
             
                    <div class="buttonWrapper">
                       
                        
                        <label>{{idType}} (Back)</label>

                      <div class="dropbox text-white" >
                          <input type="file" ref="fileInput" @change="handleFileSelectBack">
                          <p class="text-white">Selected file: {{ selectedFileBack }}</p>
                        </div>

                    </div>
                    <div>
                         <button type="submit" class="btn btn-main" id="saveBtn" :disabled="isSubmitting">Submit</button>
                    </div>

                

           </form>
           <form v-on:submit.prevent="uploadSelfie($event)" v-if="step == '3'">

            <div>
              <form>
                  <!-- <div class="form-check px-5">
                      <input
                        class="form-check-input"
                        v-model="idType"
                        type="radio"
                        value="poa"
                        
                      />
                      <label class="form-check-label" for="poa">
                        Proof of Address
                      </label>
                    </div> -->
                    <div class="form-check px-5">
                      <input
                        class="form-check-input"
                        v-model="idType"
                        type="radio"
                        value="selfie"
                        
                      />
                      <label class="form-check-label" for="selfie">
                        Selfie holding your ID
                      </label>
                    </div>
              </form>
            </div>
             
            <div class="buttonWrapper">
               
                
                <label>{{idType}}</label>

              <div class="dropbox text-white" >
                  <input type="file" ref="fileInput" @change="handleFileSelectBack">
                  <p class="text-white">Selected file: {{ selectedFileBack }}</p>
                </div>

            </div>
            <div>
                 <button type="submit" class="btn btn-main" id="saveBtn" :disabled="isSubmittingSelfie">Submit</button>
            </div>

        

          </form>
          <form v-on:submit.prevent="uploadPoa($event)" v-if="step == '4'">

            <div>
              <form>
                  <div class="form-check px-5">
                      <input
                        class="form-check-input"
                        v-model="idType"
                        type="radio"
                        value="poa"
                        
                      />
                      <label class="form-check-label" for="poa">
                        Proof of Address
                      </label>
                    </div>
                   
              </form>
            </div>
             
            <div class="buttonWrapper">
               
                
                <label>{{idType}} </label>

              <div class="dropbox text-white" >
                  <input type="file" ref="fileInput" @change="handleFileSelect">
                  <p class="text-white">Selected file: {{ selectedFileBack }}</p>
                </div>

            </div>
            <div>
                 <button type="submit" class="btn btn-main" id="saveBtn" :disabled="isSubmittingPoa">Submit</button>
            </div>

        

   </form>
         
       
          <!--FAILED-->
          <div v-if="isFailed">
            <h2 class="red">Upload failed.</h2>
           
            <p  class="red">
              <a href="javascript:void(0)" @click="closeModal()">Try again</a>
            </p>
          </div>
          <div class="text-center">
            <img class="iconImg" >
         </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props:["memberName"],
    components: {

    },
    data: function() {
        return {
          uploadError:'',
            step:'0',
            idType:'',
            description:'validId',
            selectedFile:'',
            selectedFileBack:'',
            idNumber:'111',
            uploadFieldName: 'imageFile',
            uploadFieldNameBack: 'imageFile',
            isSuccess: false,
            bothSuccess: false,
            isFailed: false,
            isLoading: false,
            response:'',
            errors: {
                profile: {
                    show:false,
                    msg: ""
                },
            },
            isSubmitting: false,
            isSubmittingSelfie:false,
            isSubmittingPoa: false
            
        }
    },

    computed: {
     
    },

    mounted() {
     
    },

    watch: {
      
    },

    methods: {
      uploadNow(){
        this.step = '1'
      },
          handleFileSelect() {
          const file = this.$refs.fileInput.files[0];
          this.selectedFile = file ? file.name : '';
          },
          handleFileSelectBack() {
          const file = this.$refs.fileInput.files[0];
          this.selectedFileBack = file ? file.name : '';
          },

        closeModal(){
            this.$emit("close")
        },

        done(){
          this.$emit("close")
        },
         uploadImage(event) {
            
           if(this.idType == "" || this.idType == null || this.idType == undefined)  {
            this.errors.profile.show = true;
            this.errors.profile.msg = "This is required";
            return false;
            }
            if(this.idNumber == "" || this.idNumber == null || this.idNumber == undefined)  {
            this.errors.profile.show = true;
            this.errors.profile.msg = "This is required";
            return false;
            } 

            const params = {
              poidImageFile: this.selectedFile,
              poidType: this.idType,
              poidDescription: this.description,
              poidNumber: this.idNumber,
              }


         let data = new FormData();
                    data.append('poidImageFile', this.$refs.fileInput.files[0]);
                    data.append('poidType', this.idType);
                    data.append('poidDescription',this.description); 
                    data.append('poidNumber', this.idNumber);
                    

            
            const options = {
                method: 'POST',
                headers: {
                      'content-type': 'image/png',
                      'blox-authz': localStorage.getItem("tokenId") 
                },
                data,
                url: '/cx/uploadpoid',
            };

           this.axios(options).then(
                response => {
                    console.log(response.request.status)
                    this.response = response.request.status
                    this.isLoading = true 
                    this.isSubmitting = true
                    
                    if(this.response = 200){
                     
                        setTimeout( () => {
                            this.isLoading = false
                            this.isSubmitting =false
                            
                          if(this.idType != 'passport'){
                              this.step = '2'
                           } 
                          
                           if(this.idType === 'passport'){
                              this.step = '3'
                           }
                         
                            
                        },1000)
                    } else {
                        this.isFailed = true
                        setTimeout(()=>{
                        this.isFailed = false
                      },2000)
                    }
                }
              ).catch((err) => {
                  if(this.response = 500){
                      this.isFailed = true
                      setTimeout(()=>{
                        this.isFailed = false
                      },2000)

                  }

          })
        },
        uploadImageBack(event) {
          

          const params = {
              poidImageFile: this.selectedFileBack,
              poidType: this.idType,
              poidDescription: this.description,
              poidNumber: this.idNumber,
              }


         let data = new FormData();
                    data.append('poidImageFile', this.$refs.fileInput.files[0]);
                    data.append('poidType', this.idType);
                    data.append('poidDescription',this.description); 
                    data.append('poidNumber', this.idNumber);
                    

            
            const options = {
                method: 'POST',
                headers: {
                      'content-type': 'image/png',
                      'blox-authz': localStorage.getItem("tokenId") 
                },
                data,
                url: '/cx/uploadpoid',
            };

           this.axios(options).then(
                response => {
                    console.log(response.request.status)
                    this.response = response.request.status
                    this.isLoading = true 
                    this.isSubmitting = true
                    if(this.response = 200){
                        
                        setTimeout( () => {
                            this.isLoading = false
                            this.isSubmitting = false
                            this.step = '3'

                        },3000)

                        
                    } else {
                        this.isFailed = true
                        setTimeout(()=>{
                        this.isFailed = false
                      },2000)
                    }
                    


                }
            ).catch((err) => {
                  if(this.response = 500){
                      this.isFailed = true
                      setTimeout(()=>{
                        this.isFailed = false
                      },2000)
                  }

          })
        },
        uploadSelfie(event) {
          

          const params = {
              poidImageFile: this.selectedFileBack,
              poidType: 'selfie',
              poidDescription: 'selfie',
              poidNumber: this.idNumber,
              }


         let data = new FormData();
                    data.append('poidImageFile', this.$refs.fileInput.files[0]);
                    data.append('poidType', 'selfie');
                    data.append('poidDescription','selfie'); 
                    data.append('poidNumber', this.idNumber);
                    

            
            const options = {
                method: 'POST',
                headers: {
                      'content-type': 'image/png',
                      'blox-authz': localStorage.getItem("tokenId") 
                },
                data,
                url: '/cx/uploadpoid',
            };

           this.axios(options).then(
                response => {
                    console.log(response.request.status)
                    this.response = response.request.status
                    this.isLoading = true 
                    this.isSubmittingSelfie = true
                    if(this.response = 200){
                        
                        setTimeout( () => {
                            this.isLoading = false
                            this.isSubmittingSelfie = false
                            this.step = '4'

                        },3000)

                        
                    } else {
                        this.isFailed = true
                        setTimeout(()=>{
                        this.isFailed = false
                      },2000)
                    }
                    


                }
            ).catch((err) => {
                  if(this.response = 500){
                      this.isFailed = true
                      setTimeout(()=>{
                        this.isFailed = false
                      },2000)
                  }

          })
        },
        uploadPoa(event) {
          

          const params = {
              poidImageFile: this.selectedFileBack,
              poidType: 'poa',
              poidDescription: 'poa',
              poidNumber: this.idNumber,
              }


         let data = new FormData();
                    data.append('poidImageFile', this.$refs.fileInput.files[0]);
                    data.append('poidType', 'poa');
                    data.append('poidDescription','poa'); 
                    data.append('poidNumber', this.idNumber);
                    

            
            const options = {
                method: 'POST',
                headers: {
                      'content-type': 'image/png',
                      'blox-authz': localStorage.getItem("tokenId") 
                },
                data,
                url: '/cx/uploadpoid',
            };

           this.axios(options).then(
                response => {
                    console.log(response.request.status)
                    this.response = response.request.status
                    this.isLoading = true 
                    this.isSubmittingPoa = true
                    if(this.response = 200){
                        
                        setTimeout( () => {
                            this.isLoading = false
                            this.isSubmittingPoa = false
                            this.step = '5'

                        },3000)

                        
                    } else {
                        this.isFailed = true
                        setTimeout(()=>{
                        this.isFailed = false
                      },2000)
                    }
                    


                }
            ).catch((err) => {
                  if(this.response = 500){
                      this.isFailed = true
                      setTimeout(()=>{
                        this.isFailed = false
                      },2000)
                  }

          })
        },
      
    }
}
</script>

<style scoped>
  .backdrop {
    position: fixed; 
    z-index: 98; 
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow: auto; 
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0,0.4); 
  }
  
  .b-modal {
    background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
    margin: 5% auto; 
    padding: 20px;
    max-width: 1000px;
     min-width: 250px;
     width: 100%;
  }
  /* .backdrop {
      top: 0;
      right:0;
      position: fixed;
      width: 100vw;
      height: 100vh;
      z-index: 98;
      background: rgba(0, 0, 0, 0.6);
  
  }
  .b-modal{
    max-width: 700px;
      height: auto;
      margin: 100px auto;
      background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
      border-radius: 10px;
      z-index: 2;
      padding-bottom: 50px;
      font-family: 'Light';
      width: 100%;
    } */
  
  .modal-header {
  
      background-color: #0E111E;
      font-family: 'Light';
  }
  
  .modal-title{
      margin: 0 auto;
      color:white;
  }
  
  .modalBody {
    background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
      width:100%;
      padding:10px;
     
  }
  
  .buttonWrapper {
      width:100%;
      font-family: 'Light';
  }
  
  .x {
      cursor:pointer;
  }
  
   .form-group{
          width: 100%;
      }
  
      .btn-main{
          margin: 30px auto;
          width: 100%;
          font-family: 'Light';
      }
      .btn-main:hover{
          color: white;
          font-family: 'Light';
      }
   
  
  
      @media screen and (max-width: 1100px) {
     
        .form{
            display: flex;
          }
      }
     @media screen and (max-width: 800px) {
      .form{
            display: flex;
          }
          
    }
  
      @media screen and (max-width: 600px) {
        .b-modal {
              width: 250px;
             
          }
        .form{
            display: block;
          }
          .btn-main{
          margin: 30px auto;
          width: 100px;
          font-family: 'Light';
      }
          
      }
  
      @media screen and (min-width: 260px) and (max-width: 420px) {
  
         .b-modal {
              width: 250px;
             
          }
          .btn-main{
          margin: 30px auto;
          width: 100px;
          font-family: 'Light';
      }
  
          
  
      }
  
      @media screen and (min-width: 480px) and (max-width: 580px) {
          .b-modal {
            width: 350px;
          }
          .form{
            display: block;
          }
          .btn-main{
          margin: 30px auto;
          width: 100px;
          font-family: 'Light';
      }
  
         
          
      }
      .wrapper{
          display: flex;
           padding: 2em;
          justify-content: center !important;
          margin: 0 auto !important;
          width: 100%;
      }
     
        .product-grid {
          display: flex;
         
        }
  
      .product-card {
        background-color: #0E111E;
          box-shadow:0 0 30px rgba(0,0,0,0.3);
          border: 1px solid transparent;
          border-radius: 12px;
          width: 7rem;
          height: auto;
          text-align: center;
          margin: 10px !important;
          color: white;
          cursor: none;
          font-size: 10px;
      
      }
  
  
      .product-image {
        padding: 10px 0px;
          position: relative;
          overflow: hidden;
          cursor: pointer;
          margin: 0 auto;
          background-color: none !important;
        width: 100%;
        max-width: 80px;
        height: auto;
        margin-bottom: 10px;
      }
      .logo{
        width: 5rem;
        padding: 10px;
      }
  </style>